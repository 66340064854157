import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { SafeComponent } from '@fiverr-private/orca';
import { Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import TrustedByItem from '../TrustedByItem';
import { COMPANIES_WHO_TRUST } from './constants';

const TrustedBySection = () => (
  <Stack
    gap={{ default: '3', md: '14' }}
    direction={{ default: 'column', md: 'row' }}
    hidden={{ default: true, sm: false }}
    justifyContent="spaceBetween"
    alignItems="flexStart"
    width="100%"
  >
    <Typography color="white" fontWeight="normal" whiteSpace="nowrap">
      <I18n k="logged_out_homepage.trusted_by.title" />:
    </Typography>
    <Stack direction="row" gap="12" rowGap="3" wrap="wrap" width="100%" alignItems="center">
      {COMPANIES_WHO_TRUST.map(({ src, alt }) => (
        <TrustedByItem src={src} alt={alt} key={alt} />
      ))}
    </Stack>
  </Stack>
);

export default SafeComponent(TrustedBySection);
