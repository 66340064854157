import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { imgPathWithTransforms, imgSrcSetFull } from '../../utils/image';
import { DPR_1 } from '../../utils/constants';

const LG_WIDTH = 188;
const SM_WIDTH = 130;

const getTouchSrcSet = (filename) => imgSrcSetFull({ width: SM_WIDTH, img: filename });
const getTouchSrc = (filename) => imgPathWithTransforms({ width: SM_WIDTH, dpr: DPR_1, img: filename });
const getSrc = (filename) => imgPathWithTransforms({ width: LG_WIDTH, dpr: DPR_1, img: filename });

const getPictureData = (filename) => [
    {
        srcSet: imgSrcSetFull({ width: SM_WIDTH, img: filename }),
        media: '(max-width: 899px)',
        width: 130,
        height: 108,
    },
    {
        srcSet: imgSrcSetFull({ width: LG_WIDTH, img: filename }),
        media: '(min-width: 900px)',
        width: 188,
        height: 157,
    },
];

export const SUB_CATEGORIES_NAMES = {
    AI_ARTISTS: 'ai_artists',
    CREATIVE_LOGO_DESIGN: 'creative_logo_design',
    WORDPRESS_SERVICES: 'wordpress_services',
    WEBSITE_DEVELOPMENT: 'website_development',
    SOFTWARE_DEVELOPMENT: 'software_development',
    ARCHITECTURAL_DESIGN: 'architectural_design_services',
    SOCIAL_MARKETING: 'social_marketing',
    DATA_SCIENCE: 'data_science',
    PRODUCT_PHOTOGRAPHERS: 'product_photographers',
    ECOMMERCE_MARKETING: 'ecommerce_marketing',
    VIDEO_EDITING: 'video_editing',
    VOICE_OVERS: 'voice_overs',
    ANIMATED_EXPLAINER: 'animated_explainer',
    SOCIAL_MEDIA: 'social_media',
    SEO_SERVICES: 'seo_services',
    ILLUSTRATION: 'illustration',
    TRANSLATION: 'translation',
    DATA_ENTRY: 'data_entry',
    BOOK_COVERS: 'book_covers',
    ECOMMERCE_MANAGEMENT: 'ecommerce_management',
    IMAGE_EDITING: 'image_editing',
    UGC_VIDEO: 'ugc_video',
};

const SUB_CATEGORIES = {
    [SUB_CATEGORIES_NAMES.ECOMMERCE_MARKETING]: ({ locale }) => {
        const ECOMMERCE_MARKETING = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '798403f5b92b1b5af997acc704a3d21c-1702465156474/e-commerce.png',
        };

        const SRC = ECOMMERCE_MARKETING[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ECOMMERCE_MARKETING,
            subCategoryId: 325,
            categorySlug: 'online-marketing',
            subCategorySlug: 'e-commerce-marketing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.VIDEO_EDITING]: ({ locale }) => {
        const VIDEO_EDITING = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '798403f5b92b1b5af997acc704a3d21c-1702465156494/video-editing.png',
        };

        const SRC = VIDEO_EDITING[locale];

        return {
            name: SUB_CATEGORIES_NAMES.VIDEO_EDITING,
            subCategoryId: 99,
            categorySlug: 'video-animation',
            subCategorySlug: 'video-editing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.PRODUCT_PHOTOGRAPHERS]: ({ locale }) => {
        const PRODUCT_PHOTOGRAPHERS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '798403f5b92b1b5af997acc704a3d21c-1702465156481/product-photography.png',
        };

        const SRC = PRODUCT_PHOTOGRAPHERS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.PRODUCT_PHOTOGRAPHERS,
            subCategoryId: 503,
            categorySlug: 'photography',
            subCategorySlug: 'product-photographers',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.DATA_SCIENCE]: ({ locale }) => {
        const DATA_SCIENCE = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '798403f5b92b1b5af997acc704a3d21c-1702465156495/data-science.png',
        };

        const SRC = DATA_SCIENCE[locale];

        return {
            name: SUB_CATEGORIES_NAMES.DATA_SCIENCE,
            subCategoryId: 455,
            categorySlug: 'data',
            subCategorySlug: 'data-science',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.SOCIAL_MARKETING]: ({ locale }) => {
        const SOCIAL_MARKETING = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '798403f5b92b1b5af997acc704a3d21c-1702465156476/social-media-marketing.png',
        };

        const SRC = SOCIAL_MARKETING[locale];

        return {
            name: SUB_CATEGORIES_NAMES.SOCIAL_MARKETING,
            subCategoryId: 67,
            categorySlug: 'online-marketing',
            subCategorySlug: 'social-marketing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.WEBSITE_DEVELOPMENT]: ({ locale }) => {
        const WEBSITE_DEVELOPMENT = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '798403f5b92b1b5af997acc704a3d21c-1702465156477/website-development.png',
        };

        const SRC = WEBSITE_DEVELOPMENT[locale];

        return {
            name: SUB_CATEGORIES_NAMES.WEBSITE_DEVELOPMENT,
            subCategoryId: 514,
            categorySlug: 'programming-tech',
            subCategorySlug: 'website-development',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.SOFTWARE_DEVELOPMENT]: ({ locale }) => {
        const SOFTWARE_DEVELOPMENT = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '798403f5b92b1b5af997acc704a3d21c-1702465156476/software-development.png',
        };

        const SRC = SOFTWARE_DEVELOPMENT[locale];

        return {
            name: SUB_CATEGORIES_NAMES.SOFTWARE_DEVELOPMENT,
            subCategoryId: 140,
            categorySlug: 'programming-tech',
            subCategorySlug: 'software-development',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ARCHITECTURAL_DESIGN]: ({ locale }) => {
        const ARCHITECTURAL_DESIGN = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '798403f5b92b1b5af997acc704a3d21c-1702465156473/architecture-design.png',
        };

        const SRC = ARCHITECTURAL_DESIGN[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ARCHITECTURAL_DESIGN,
            subCategoryId: 150,
            categorySlug: 'graphics-design',
            subCategorySlug: 'architectural-design-services',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.AI_ARTISTS]: ({ locale }) => {
        const AI_ARTISTS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161247/ai-artists-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '79378eccf7a43039b30e807fdc73f087-1689604975959/ai-artists-2x.png',
        };

        const SRC = AI_ARTISTS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.AI_ARTISTS,
            subCategoryId: 526,
            categorySlug: 'graphics-design',
            subCategorySlug: 'ai-art-prompt',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]: ({ locale }) => {
        const LOGO_DESIGN = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '798403f5b92b1b5af997acc704a3d21c-1702465156494/logo-design.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'a795b343a3d8533c3f57f8195e1fac26-1689604654203/logo-design-2x.png',
        };

        const SRC = LOGO_DESIGN[locale];

        return {
            name: SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN,
            subCategoryId: 49,
            categorySlug: 'graphics-design',
            subCategorySlug: 'creative-logo-design',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES]: ({ locale }) => {
        const WORDPRESS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161257/wordpress-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '76a7857fe5d8df4d50d995c9582bdf9e-1689605043532/wordpress-2x.png',
        };

        const SRC = WORDPRESS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES,
            subCategoryId: 91,
            categorySlug: 'programming-tech',
            subCategorySlug: 'website-development',
            nestedSubCategorySlug: 'wordpress-development',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.VOICE_OVERS]: ({ locale }) => {
        const VOICE_OVER = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '798403f5b92b1b5af997acc704a3d21c-1702465156479/voice-over.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '6d2686026e20bab48384dc0f9acaadd4-1689605220236/voiceover-2x.png',
        };

        const SRC = VOICE_OVER[locale];

        return {
            name: SUB_CATEGORIES_NAMES.VOICE_OVERS,
            subCategoryId: 13,
            categorySlug: 'music-audio',
            subCategorySlug: 'voice-overs',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ECOMMERCE_MANAGEMENT]: ({ locale }) => {
        const ECOMMERCE_MANAGEMENT = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '894fca57bacf78fd8f52b8e66992ecb3-1690384040023/ecommerce-management-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]:
                '803122869deaaa7c4a53078f2faff243-1689605678912/ecommerce-management-2x.png',
        };

        const SRC = ECOMMERCE_MANAGEMENT[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ECOMMERCE_MANAGEMENT,
            subCategoryId: 423,
            categorySlug: 'business',
            subCategorySlug: 'ecommerce-management',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.IMAGE_EDITING]: ({ locale }) => {
        const IMAGE_EDITING = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '894fca57bacf78fd8f52b8e66992ecb3-1690384040038/image-editing-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]:
                '50076461f1df604b1bee346076997140-1689605749600/image-editing-2x.png',
        };

        const SRC = IMAGE_EDITING[locale];

        return {
            name: SUB_CATEGORIES_NAMES.IMAGE_EDITING,
            subCategoryId: 53,
            categorySlug: 'graphics-design',
            subCategorySlug: 'image-editing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER]: ({ locale }) => {
        const ANIMATED_EXPLAINER = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '7ead3b2056987e6fa3aad69cf897a50b-1690383161245/animated-explainer-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]:
                'd6a68b4b7ed4c9d8ded59c9707313b8f-1689605312002/animated-explainer-2x.png',
        };

        const SRC = ANIMATED_EXPLAINER[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER,
            subCategoryId: 228,
            categorySlug: 'video-animation',
            subCategorySlug: 'animated-explainer-videos',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.SOCIAL_MEDIA]: ({ locale }) => {
        const SOCIAL = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161249/social-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'faf41b5857a9ec20e5b539a1a0be9c7b-1689605119319/social-2x.png',
        };

        const SRC = SOCIAL[locale];

        return {
            name: SUB_CATEGORIES_NAMES.SOCIAL_MEDIA,
            subCategoryId: 67,
            categorySlug: 'online-marketing',
            subCategorySlug: 'social-marketing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.SEO_SERVICES]: ({ locale }) => {
        const SEO = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '798403f5b92b1b5af997acc704a3d21c-1702465156488/seo.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '55570df37f9341385981a3cbae1a9403-1689605443850/seo-2x.png',
        };

        const SRC = SEO[locale];

        return {
            name: SUB_CATEGORIES_NAMES.SEO_SERVICES,
            subCategoryId: 65,
            categorySlug: 'online-marketing',
            subCategorySlug: 'seo-services',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ILLUSTRATION]: ({ locale }) => {
        const ILLUSTRATION = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161236/illustration-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '611f0230eee2eeefbff65e393c952e7a-1689605381675/illustration-2x.png',
        };

        const SRC = ILLUSTRATION[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ILLUSTRATION,
            subCategoryId: 50,
            categorySlug: 'graphics-design',
            subCategorySlug: 'digital-illustration',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.TRANSLATION]: ({ locale }) => {
        const TRANSLATION = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161247/translation-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'c72f1b801d5eb2ac597c9f1f042c70a7-1689605559755/translation-2x.png',
        };

        const SRC = TRANSLATION[locale];

        return {
            name: SUB_CATEGORIES_NAMES.TRANSLATION,
            subCategoryId: 108,
            categorySlug: 'writing-translation',
            subCategorySlug: 'quality-translation-services',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.DATA_ENTRY]: ({ locale }) => {
        const DATA_ENTRY = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161248/data-entry-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'a710f4bd61f620eaa3ce32581b4eaa15-1689605611316/data-entry-2x.png',
        };

        const SRC = DATA_ENTRY[locale];

        return {
            name: SUB_CATEGORIES_NAMES.DATA_ENTRY,
            subCategoryId: 456,
            categorySlug: 'data',
            subCategorySlug: 'data-entry',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.BOOK_COVERS]: ({ locale }) => {
        const BOOK_COVERS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161238/book-covers-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '3563e10e42e58fcdb8d119574f4d383d-1689605502570/book-covers-2x.png',
        };

        const SRC = BOOK_COVERS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.BOOK_COVERS,
            subCategoryId: 51,
            nestedSubCategoryId: 2318,
            categorySlug: 'graphics-design',
            subCategorySlug: 'book-design',
            nestedSubCategorySlug: 'cover',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.UGC_VIDEO]: ({ locale }) => {
        const UGC_VIDEO = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                'ece24f7f595e2dd44b26567705d1c600-1728279781879/UGC%20Video%20img.png',
        };

        const SRC = UGC_VIDEO[locale];

        return {
            name: SUB_CATEGORIES_NAMES.UGC_VIDEO,
            subCategoryId: 549,
            categorySlug: 'video-animation',
            subCategorySlug: 'ugc-videos',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
};

const SUB_CATEGORIES_LIST = {
    [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: [
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.WEBSITE_DEVELOPMENT]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SEO_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ARCHITECTURAL_DESIGN]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.VOICE_OVERS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SOCIAL_MARKETING]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.UGC_VIDEO]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SOFTWARE_DEVELOPMENT]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.DATA_SCIENCE]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.PRODUCT_PHOTOGRAPHERS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ECOMMERCE_MARKETING]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.VIDEO_EDITING]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
    ],
    [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: [
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.AI_ARTISTS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.VOICE_OVERS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SOCIAL_MEDIA]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SEO_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ILLUSTRATION]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.TRANSLATION]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ECOMMERCE_MANAGEMENT]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.IMAGE_EDITING]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
    ],
};

export const getSubCategories = ({ locale }) => {
    if (locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE) {
        return SUB_CATEGORIES_LIST[SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE];
    }

    return SUB_CATEGORIES_LIST[SUPPORTED_FIVERR_LOCALES_KEYS.EN_US];
};
