import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { getContext } from '@fiverr-private/fiverr_context';
import { Domingo } from '@fiverr-private/domingo';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../utils/biEvents';
import { BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE } from '../../../utils/biEvents/events/bigQueryEvents';
import { MP_CATEGORIES_ICON_CLICKED } from '../../../utils/biEvents/events/mixPanelEvents';

import { inExperiment } from '../../../utils/experiments';
import { DOLPHINS_TOF_PRO_REDIRECT } from '../../../utils/experiments/experimentsEnum';
import { categoriesForTofProRedirectExperiment } from '../config';
import { imageCustomClass, shadowCustomClass, categoryCustomClass } from './styles.ve.css';

const getCategoryUrl = ({ categorySlug, subCategorySlug, pos }, inDolphinsTofProRedirectTest = false) => {
  const { url } = getContext();

  let path = Domingo(url).path(
    'short_category',
    { id: categorySlug },
    {
      query: {
        source: 'hplo_cat_sec_TOP',
        pos,
      },
    }
  );

  if (subCategorySlug) {
    path = Domingo(url).path(
      'short_category_sub_category',
      {
        category_id: categorySlug,
        id: subCategorySlug,
      },
      {
        query: {
          source: 'hplo_cat_sec_TOP',
          pos,
        },
      }
    );
  }

  if (inDolphinsTofProRedirectTest) {
    return path.pro().url;
  }

  return path.url;
};

const getLink = ({ directLink, categorySlug, subCategorySlug, position }, inDolphinsTofProRedirectTest = false) => {
  if (directLink) {
    return `${directLink}?source=hplo_cat_sec_TOP&pos=${position}`;
  }

  return getCategoryUrl({ categorySlug, subCategorySlug, pos: position }, inDolphinsTofProRedirectTest);
};

interface CardProps {
  categoryName: string;
  categorySlug?: string;
  subCategorySlug?: string;
  categoryImage: string;
  directLink?: string;
  categoryId?: number;
  position: number;
}

const Card = ({
  categoryName,
  categorySlug,
  subCategorySlug,
  categoryImage,
  categoryId,
  position,
  directLink,
}: CardProps) => {
  const { abTests } = getContext();
  const inDolphinsTofProRedirectTest =
    inExperiment(DOLPHINS_TOF_PRO_REDIRECT, abTests) &&
    categoryId &&
    categoriesForTofProRedirectExperiment.includes(categoryId);
  const href = getLink({ directLink, categorySlug, subCategorySlug, position }, !!inDolphinsTofProRedirectTest);

  const onClick = () => {
    sendMixPanelEvent({
      eventName: MP_CATEGORIES_ICON_CLICKED,
      params: {
        categoryName,
        position,
      },
    });

    sendBigQueryEvent({
      eventName: BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE,
      params: { id: categoryId, name: categoryName, position },
    });
  };

  return (
    <Stack justifyContent="center" padding={{ md: '1.5' }}>
      <a className={categoryCustomClass} href={href} onClick={onClick}>
        <Stack
          justifyContent={{ default: 'center', md: 'flexStart' }}
          alignItems={{ default: 'center', md: 'flexStart' }}
          borderRadius="2xl"
          height={{ default: 98, md: 'auto' }}
          className={shadowCustomClass}
        >
          <img src={categoryImage} alt={categoryName} loading="lazy" className={imageCustomClass} />
        </Stack>
        <Text
          as="p"
          size="b_sm"
          fontWeight="semibold"
          textAlign={{ default: 'center', md: 'start' }}
          wordBreak="keepAll"
          height={{ md: 48 }}
        >
          <I18n k={`logged_out_homepage.categories.${categoryName}`} />
        </Text>
      </a>
    </Stack>
  );
};

export default Card;
