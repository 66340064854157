import React, { useEffect } from 'react';
import { bool, node } from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import AppContext from '../../context/AppContext';
import { sendMixPanelEvent } from '../../utils/biEvents';
import { MP_SHOW_PAGE_NAME } from '../../utils/biEvents/events/mixPanelEvents';
import { MIXPANEL_EVENT_TYPES } from '../../utils/types/mixpanelEventTypes';

import './style.scss';

const EntryWrapper = ({ displayBrandCampaign, removeBottomSpace = false, children }) => {
    const { locale, isMobile } = getContext();

    useEffect(() => {
        sendMixPanelEvent({
            eventName: MP_SHOW_PAGE_NAME,
            eventType: MIXPANEL_EVENT_TYPES.PAGE_VIEW,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const context = {
        displayBrandCampaign,
    };

    const shouldApplyStyles = locale === SUPPORTED_FIVERR_LOCALES_KEYS.EN_US && !isMobile;

    return (
        <div
            className={classNames('logged-out-homepage', 'is-revamp', {
                'p-b-0': removeBottomSpace,
                'logged-out-homepage-de': locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE,
                'logged-out-homepage-test': shouldApplyStyles,
            })}
        >
            <AppContext.Provider value={context}>{children}</AppContext.Provider>
        </div>
    );
};

EntryWrapper.propTypes = {
    displayBrandCampaign: bool,
    removeBottomSpace: bool,
    children: node,
};

export default EntryWrapper;
