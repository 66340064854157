import Meta from '../../../logged_out_homepage/assets/companies/meta.svg';
import Google from '../../../logged_out_homepage/assets/companies/google.svg';
import Netflix from '../../../logged_out_homepage/assets/companies/netflix.svg';
import PnG from '../../../logged_out_homepage/assets/companies/pg.svg';
import Paypal from '../../../logged_out_homepage/assets/companies/paypal.svg';
import Payoneer from '../../../logged_out_homepage/assets/companies/payoneer.svg';

export const COMPANIES_WHO_TRUST = [
    {
        src: Meta,
        alt: 'Trusted by Meta',
    },
    {
        src: Google,
        alt: 'Trusted by Google',
    },
    {
        src: Netflix,
        alt: 'Trusted by Netflix',
    },
    {
        src: PnG,
        alt: 'Trusted by PnG',
    },
    {
        src: Paypal,
        alt: 'Trusted by Paypal',
    },
    {
        src: Payoneer,
        alt: 'Trusted by Payoneer',
    },
];
