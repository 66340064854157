import React, { useEffect, useRef, useState } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { SafeComponent } from '@fiverr-private/orca';
import { Video } from '@fiverr-private/media';
import SearchBar from '@fiverr-private/search_bar';
import { translate, I18n } from '@fiverr-private/i18n-react';
import VideoControlButton from '../VideoControlButton';
import PopularServices from '../PopularServices';
import TrustedBySection from '../TrustedBySection';
import { VIDEO_SOURCES } from './constants';
import hero from './assets/hero.png';

import styles from './styles.module.scss';

const VideoHeroBanner = () => {
  const videoRef = useRef(null);
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia && window?.matchMedia('(prefers-reduced-motion: reduce)');
    setPrefersReducedMotion(mediaQuery?.matches);

    const handleChange = () => setPrefersReducedMotion(mediaQuery?.matches);
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <Stack width="100%" position="relative" height={{ default: '572px', sm: '680px' }} marginBottom="7">
      <Container className="wrapper" width="100%" position="absolute" top="0px" right="0px" left="0px" bottom="0px">
        {prefersReducedMotion && <Container className={styles.placeholder} aria-hidden />}
        {!prefersReducedMotion && (
          <Video
            width="100%"
            height="100%"
            autoPlay
            playsInline
            preload="auto"
            poster={hero}
            muted
            loop={true}
            controls={false}
            ref={videoRef}
            sources={[VIDEO_SOURCES.desktop]}
            aria-hidden
          />
        )}
      </Container>
      <Stack
        width="100%"
        height="100%"
        display="flex"
        direction="column"
        alignItems="flexStart"
        zIndex="1"
        margin="auto"
        maxWidth="1400px"
        overflow="auto"
        className={styles.container}
      >
        <Stack
          height="100%"
          display="flex"
          direction="column"
          alignItems="flexStart"
          maxWidth={{ default: '100%', sm: '512px', md: '705px', lg: '891px' }}
          zIndex="2"
          overflow="visible"
          gap="7"
        >
          <Typography
            color="white"
            style={{ fontWeight: 280 }}
            marginBottom={{ default: 'auto', sm: '0' }}
            className={styles.title}
            as="h1"
          >
            <I18n k="logged_out_homepage.video_top_banner.title" />
          </Typography>
          <Container width="100%" maxWidth="100%">
            <SearchBar
              placeholder={translate('logged_out_homepage.hero.search')}
              source="main_banner"
              submitButtonType="button_inside"
              theme="dark"
            />
          </Container>
          <PopularServices />
        </Stack>
        <Stack marginTop={{ default: '5', sm: 'auto' }} width="100%" alignItems="center">
          <TrustedBySection />
          <VideoControlButton videoRef={videoRef} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SafeComponent(VideoHeroBanner);
