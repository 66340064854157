import React from 'react';
import { SafeComponent } from '@fiverr-private/orca';
import { Button } from '@fiverr-private/button';
import { Text } from '@fiverr-private/typography';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { Stack } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../logged_out_homepage/utils/biEvents';
import { MP_POPULAR_SERVICES_CLICKED } from '../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';

interface ServiceItemProps {
  index: number;
  label: string;
  href: string;
  eventName: string;
}

const ServiceItem = ({ label, href, eventName, index }: ServiceItemProps) => {
  const sendServiceClickMixpanelEvent = () => {
    sendBigQueryEvent({ eventName });

    sendMixPanelEvent({
      eventName: MP_POPULAR_SERVICES_CLICKED,
      params: {
        element: label,
        order: index + 1,
      },
    });
  };

  return (
    <Button
      intent="primary"
      variant="outline"
      colorScheme="white"
      size="md"
      target="_blank"
      onClick={sendServiceClickMixpanelEvent}
      href={href}
      flex={{ default: 'auto', md: '1 1 auto' }}
      style={{ maxWidth: 'max-content', backgroundColor: theme.colors.white_10 }}
    >
      <Stack alignItems="center" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        <Text
          color="white"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          width="100%"
          overflow="hidden"
          textAlign="center"
        >
          {label}
        </Text>
        <ArrowRightIcon />
      </Stack>
    </Button>
  );
};

export default SafeComponent(ServiceItem);
