import pathfinder from '@fiverr-private/pathfinder';
import {
    BQ_POPULAR_SERVICE_CLICK_ARCHITECTURE_AND_INTERIOR,
    BQ_POPULAR_SERVICE_CLICK_LOGO_DESIGN,
    BQ_POPULAR_SERVICE_CLICK_VIDEO_EDITING,
    BQ_POPULAR_SERVICE_CLICK_WEBSITE_DEVELOPMENT,
} from '../../../logged_out_homepage/utils/biEvents/events/bigQueryEvents';

interface PopularService {
    label: string;
    href: string;
    eventName: string;
}

const HEADER_LOHP_SOURCE = 'header_lohp';

export const POPULAR_SERVICES: PopularService[] = [
    {
        label: 'website development',
        href: pathfinder(
            'short_category_sub_category',
            { category_id: 'programming-tech', id: 'website-development' },
            { query: { source: HEADER_LOHP_SOURCE } }
        ),
        eventName: BQ_POPULAR_SERVICE_CLICK_WEBSITE_DEVELOPMENT,
    },
    {
        label: 'logo design',
        href: pathfinder(
            'short_category_sub_category',
            { category_id: 'graphics-design', id: 'creative-logo-design' },
            { query: { source: HEADER_LOHP_SOURCE } }
        ),
        eventName: BQ_POPULAR_SERVICE_CLICK_LOGO_DESIGN,
    },
    {
        label: 'video editing',
        href: pathfinder(
            'short_category_sub_category',
            { category_id: 'video-animation', id: 'video-editing' },
            { query: { source: HEADER_LOHP_SOURCE } }
        ),
        eventName: BQ_POPULAR_SERVICE_CLICK_VIDEO_EDITING,
    },
    {
        label: 'architecture & interior design',
        href: pathfinder(
            'short_category_sub_category',
            { category_id: 'graphics-design', id: 'architectural-design-services' },
            { query: { source: HEADER_LOHP_SOURCE } }
        ),
        eventName: BQ_POPULAR_SERVICE_CLICK_ARCHITECTURE_AND_INTERIOR,
    },
];
