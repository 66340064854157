import { URI } from '@fiverr-private/futile';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { SEO_LOCALES } from '../../../utils/types/language';
import { DOMAIN_CONTEXT, DOMAIN_NAME, DOMAIN_TYPE } from './constants';

/**
 * Build localized seo markup
 *
 * @param locale
 * @param originUrl
 */
export const buildSeoMarkup = (locale, originUrl) =>
    JSON.stringify({
        '@context': DOMAIN_CONTEXT,
        '@type': DOMAIN_TYPE,
        name: DOMAIN_NAME,
        url: buildDomainUrl(originUrl),
        inLanguage: buildInLanguage(locale),
    });

const buildDomainUrl = (originUrl = '') => {
    const uri = new URI(originUrl);
    const host = uri.host;
    const protocol = uri.protocol;

    return `${protocol}//${host}`;
};

const buildInLanguage = (locale = DEFAULT_FIVERR_LOCALE) => SEO_LOCALES[locale] || locale;
