import React from 'react';
import { Button as GoButton, GoArrowRightIcon } from '@fiverr-private/go_shared_ui';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { NewTag } from '../NewTag';
import GradientBg from './assets/desktop-gradient-bg.png';

interface GoVoiceOverOverlayDesktopProps {
  subCategoryNameKey: string;
}

export const GoVoiceOverOverlayDesktop = ({ subCategoryNameKey }: GoVoiceOverOverlayDesktopProps) => (
  <Container display={{ default: 'none', md: 'flex' }} alignItems="flexStart" direction="column">
    <Typography
      as="h3"
      color="white"
      paddingX="2.5"
      size="b_lg"
      lineHeight="b_xl"
      height="78px"
      overflow="hidden"
      fontWeight="bold"
    >
      <I18n k={subCategoryNameKey} />
      <Typography as="p" paddingTop="0.5" size="b_sm" lineHeight="b_xs" fontWeight="normal">
        <I18n k="logged_out_homepage.subcategory_carousel.with_ai_models" />
      </Typography>
    </Typography>
    <Stack
      background={`#000 center/cover url(${GradientBg}) no-repeat`}
      direction="row"
      width="100%"
      height="120px"
      alignItems="center"
      justifyContent="center"
      marginBottom="2.5"
      borderRadius="lg"
    >
      <GoButton>
        <I18n k="logged_out_homepage.subcategory_carousel.start_generating" />
        <GoArrowRightIcon size={16} />
      </GoButton>
    </Stack>
    <NewTag />
  </Container>
);
