export const LAZY_COMPONENTS = {
    MADE_ON_FIVERR: 'made_on_fiverr',
    TESTIMONIALS: 'testimonials',
    FIVERR_BUSINESS: 'fiverr_business',
    FIVERR_GUIDES: 'fiverr_guides',
    SIGNUP: 'signup',
    VIDEO: 'video',
    FREELANCERS: 'freelancers',
    PRO_SERVICES: 'pro_services',
    LOGO_MAKER_BANNER: 'logo_maker_banner',
    PERSONALIZED_SECTION_WRAPPER: 'personalized_section_wrapper',
};
