import React from 'react';
import cn from 'classnames';
import { Stack } from '@fiverr-private/layout_components';
import pathfinder from '@fiverr-private/pathfinder';
import { Link } from '@fiverr-private/typography';
import { sendBigQueryEvent } from '../../../../utils/biEvents';
import { BQ_CLICKED_GO_START_GENERATING } from '../../../../utils/biEvents/events/bigQueryEvents';
import { GoVoiceOverOverlayDesktop } from '../GoVoiceOverOverlayDesktop';
import { GoVoiceOverOverlayMobile } from '../GoVoiceOverOverlayMobile';

import { linkClass, overlayClass } from './styles.ve.css';

interface GoVoiceOverOverlayProps {
  subCategoryNameKey: string;
  overlayOpen?: boolean;
  setOverlayOpen: (open: boolean) => void;
}

export const GoVoiceOverOverlay = ({
  subCategoryNameKey,
  overlayOpen = false,
  setOverlayOpen,
}: GoVoiceOverOverlayProps) => {
  const buttonUrl = pathfinder('go_explore_category_page', {
    vertical: 'music-audio-generator',
  });

  const onButtonClick = () => {
    sendBigQueryEvent({
      eventName: BQ_CLICKED_GO_START_GENERATING,
      params: { elementName: 'start_generating', elementType: 'subcategory' },
    });
  };

  return (
    <Stack
      backgroundColor="black"
      position="absolute"
      top="0px"
      right="0px"
      zIndex="1"
      overflow="hidden"
      className={cn(overlayClass, { 'open-overlay': overlayOpen })}
      onMouseLeave={() => setOverlayOpen(false)}
    >
      <Link
        href={buttonUrl}
        height="100%"
        width="100%"
        className={cn(linkClass, { 'open-overlay': overlayOpen })}
        onClick={onButtonClick}
        target="_blank"
      >
        <GoVoiceOverOverlayDesktop subCategoryNameKey={subCategoryNameKey} />
        <GoVoiceOverOverlayMobile subCategoryNameKey={subCategoryNameKey} />
      </Link>
    </Stack>
  );
};
