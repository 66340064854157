import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { SafeComponent } from '@fiverr-private/orca';
import ServiceItem from '../ServiceItem';
import { POPULAR_SERVICES } from './constants';

const PopularServices = () => (
  <Stack
    direction="row"
    gap="4"
    wrap={{ default: 'nowrap', sm: 'wrap' }}
    overflow="auto"
    justifyContent="flexStart"
    width="100%"
  >
    {POPULAR_SERVICES.map(({ label, href, eventName }, index) => (
      <ServiceItem label={label} href={href} key={label} eventName={eventName} index={index} />
    ))}
  </Stack>
);

export default SafeComponent(PopularServices);
