import React from 'react';
import { Button as GoButton } from '@fiverr-private/go_shared_ui';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import GradientBg from './assets/mobile-gradient-bg.png';

interface GoVoiceOverOverlayMobileProps {
  subCategoryNameKey: string;
}

export const GoVoiceOverOverlayMobile = ({ subCategoryNameKey }: GoVoiceOverOverlayMobileProps) => (
  <Container display={{ default: 'block', md: 'none' }}>
    <Stack
      background={`#000 center/cover url(${GradientBg}) no-repeat`}
      direction="column"
      width="100%"
      height="170px"
      alignItems="center"
      justifyContent="center"
      style={{ borderRadius: '6px' }}
    >
      <Typography
        as="h3"
        color="white"
        paddingX="2.5"
        size="b_sm"
        lineHeight="b_sm"
        fontWeight="bold"
        textAlign="center"
      >
        <I18n k={subCategoryNameKey} />
        <Typography as="p" paddingTop="0.5" size="b_xs" lineHeight="b_xs" fontWeight="normal" textAlign="center">
          <I18n k="logged_out_homepage.subcategory_carousel.with_ai_models" />
        </Typography>
      </Typography>
      <GoButton>
        <I18n k="logged_out_homepage.subcategory_carousel.generate" />
      </GoButton>
    </Stack>
  </Container>
);
