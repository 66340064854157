import React, { useState } from 'react';
import { SafeComponent } from '@fiverr-private/orca';
import { IconButton } from '@fiverr-private/button';
import { PlaySolidIcon, PauseSolidIcon } from '@fiverr-private/visuals';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../logged_out_homepage/utils/biEvents';
import { MP_VIDEO_CONTROLS_CLICKED } from '../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';
import { BQ_VIDEO_PAUSE, BQ_VIDEO_PLAY } from '../../../logged_out_homepage/utils/biEvents/events/bigQueryEvents';

interface VideoControlButtonProps {
  videoRef: React.RefObject<HTMLVideoElement>;
}

const VideoControlButton = ({ videoRef }: VideoControlButtonProps) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const sendVideoControlMixpanelEvent = (action: 'Pause' | 'Play') =>
    sendMixPanelEvent({
      eventName: MP_VIDEO_CONTROLS_CLICKED,
      params: {
        action,
      },
    });

  const togglePlay = () => {
    if (!videoRef.current) {
      return;
    }

    if (isPlaying) {
      videoRef.current.pause();
      sendBigQueryEvent({ eventName: BQ_VIDEO_PAUSE });
    } else {
      videoRef.current.play();
      sendBigQueryEvent({ eventName: BQ_VIDEO_PLAY });
    }

    sendVideoControlMixpanelEvent(isPlaying ? 'Pause' : 'Play');

    setIsPlaying(!isPlaying);
  };

  return (
    <IconButton onClick={togglePlay} intent="primary" aria-label="Start/Pause video" shape="circle">
      {isPlaying ? <PauseSolidIcon color="white" /> : <PlaySolidIcon color="white" />}
    </IconButton>
  );
};

export default SafeComponent(VideoControlButton);
