import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { I18n } from '@fiverr-private/i18n-react';
import { ChevronLeftIcon, ChevronRightIcon } from '@fiverr-private/icons';
import { Section, Container, Stack, Divider } from '@fiverr-private/layout_components';
import { Button, IconButton } from '@fiverr-private/button';
import { breakpointMd, breakpointSm } from '@fiverr-private/sass/helpers';
import { ChevronDownIcon, ChevronUpIcon } from '@fiverr-private/visuals';
import SafeComponent from '../../shared/SafeComponent';
import { getImpressionEnrichment } from '../../utils/biEvents';
import { BQ_IMP_CATEGORY_ICONS_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import Card from './Card';
import { categoriesData } from './config';

import {
  innerCustomClass,
  arrowLeftCustomClass,
  arrowRightCustomClass,
  gridCustomClass,
  hiddenCustomClass,
} from './styles.ve.css';

const Categories = () => {
  const { isTouch } = getContext();

  const carouselRef = useRef<HTMLElement | null>(null);

  const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_CATEGORY_ICONS_IS_SHOWN }));

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [hiddenCount, setHiddentCount] = useState(0);

  const buttonCopy = isHidden ? 'logged_out_homepage.categories.more' : 'logged_out_homepage.categories.less';

  const toggleHidden = () => setIsHidden((prev) => !prev);

  const onScrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo(carouselRef.current.scrollWidth, 0);
    }
  };

  const onScrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo(0, 0);
    }
  };

  const handleScroll = () => {
    const list = carouselRef.current;

    if (list) {
      setCanScrollLeft(list.scrollLeft !== 0);
      setCanScrollRight(list.scrollWidth - list.offsetWidth !== list.scrollLeft);
    }
  };

  const windowResize = () => {
    if (window.innerWidth < breakpointSm) {
      return setHiddentCount(3);
    }

    if (window.innerWidth < breakpointMd) {
      return setHiddentCount(1);
    }

    return setHiddentCount(0);
  };

  useEffect(() => {
    const list = carouselRef.current;

    if (list) {
      handleScroll();

      list.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (list) {
        list.removeEventListener('scroll', handleScroll);
      }
    };
  }, [carouselRef]);

  useEffect(() => {
    windowResize();

    window.addEventListener('resize', windowResize);

    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, []);

  return (
    <Section className="sub-categories-section" paddingBottom="0" paddingTop="0">
      <Container position="relative" marginBottom={{ default: '8', xl: '10' }} ref={ref}>
        <Container overflowX={{ md: 'auto' }} className={innerCustomClass} ref={carouselRef}>
          {canScrollLeft && !isTouch && (
            <IconButton
              size="lg"
              aria-label="arrow-left"
              shape="circle"
              colorScheme="white"
              onClick={onScrollLeft}
              className={arrowLeftCustomClass}
            >
              <ChevronLeftIcon size={16} color="currentColor" />
            </IconButton>
          )}

          <Container
            className={classNames(gridCustomClass, { [hiddenCustomClass]: hiddenCount && isHidden })}
            display="grid"
            padding="3"
            columnGap={{ default: '1.5', sm: '10', md: '0' }}
            rowGap={{ default: '6', md: '0' }}
          >
            {categoriesData.map((categoryData, index) => (
              <Card key={index} data-testid="category-card" position={index + 1} {...categoryData} />
            ))}
          </Container>

          {canScrollRight && !isTouch && (
            <IconButton
              size="lg"
              shape="circle"
              aria-label="arrow-right"
              colorScheme="white"
              onClick={onScrollRight}
              className={arrowRightCustomClass}
            >
              <ChevronRightIcon size={16} color="currentColor" />
            </IconButton>
          )}
        </Container>
        {!!hiddenCount && (
          <Stack justifyContent="center" direction="column" gap="2" marginTop="6">
            <Divider height="1px" width="100%" />
            <Button variant="ghost" onClick={toggleHidden}>
              <I18n k={buttonCopy} params={{ count: hiddenCount }} />
              {isHidden ? (
                <ChevronDownIcon size="xs" color="currentColor" />
              ) : (
                <ChevronUpIcon size="xs" color="currentColor" />
              )}
            </Button>
          </Stack>
        )}
      </Container>
    </Section>
  );
};

export { Categories };
export default SafeComponent(Categories);
