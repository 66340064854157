import React, { useState } from 'react';
import { Box } from '@fiverr-private/theme';
import { Image } from '@fiverr-private/media';
import { Stack } from '@fiverr-private/layout_components';
import { GradientBadge } from '@fiverr-private/go_shared_ui';
import { GoVoiceOverOverlay } from '../GoVoiceOverOverlay';
import MaskScCard from './assets/mask-sc-card.svg';
import {
  buttonClass,
  GRADIENT_BADGE_COLORS,
  GRADIENT_BADGE_SIZE,
  GRADIENT_BADGE_SIZE_DESKTOP,
  MASK_SC_CARD_SIZE,
  MASK_SC_CARD_SIZE_DESKTOP,
} from './styles.ve.css';

interface GoVoiceOverProps {
  subCategoryNameKey: string;
}

export const GoVoiceOver = ({ subCategoryNameKey }: GoVoiceOverProps) => {
  const [overlayOpen, setOverlayOpen] = useState(false);

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOverlayOpen(!overlayOpen);
  };

  return (
    <>
      <Stack
        position="absolute"
        height={{ default: GRADIENT_BADGE_SIZE, md: GRADIENT_BADGE_SIZE_DESKTOP }}
        width={{ default: GRADIENT_BADGE_SIZE, md: GRADIENT_BADGE_SIZE_DESKTOP }}
        right={0}
        top={0}
        zIndex="1"
      >
        <Image
          position="absolute"
          right={0}
          top={0}
          src={MaskScCard}
          alt="gradiant wrapper"
          height={{ default: MASK_SC_CARD_SIZE, md: MASK_SC_CARD_SIZE_DESKTOP }}
          width={{ default: MASK_SC_CARD_SIZE, md: MASK_SC_CARD_SIZE_DESKTOP }}
        />
        <Box
          className={buttonClass}
          width="100%"
          height="100%"
          as="button"
          onClick={(event) => handleButtonClick(event)}
          hidden={overlayOpen}
        >
          <GradientBadge colors={GRADIENT_BADGE_COLORS} degrees="45" withHover />
        </Box>
      </Stack>
      <GoVoiceOverOverlay
        overlayOpen={overlayOpen}
        setOverlayOpen={setOverlayOpen}
        subCategoryNameKey={subCategoryNameKey}
      />
    </>
  );
};
