import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';

export const NewTag = () => (
  <Container
    borderRadius="2xl"
    borderWidth="sm"
    borderColor="white"
    borderStyle="solid"
    paddingX="2"
    paddingTop="1"
    paddingBottom="0.5"
    alignSelf="flexStart"
    marginLeft="2.5"
    display="flex"
  >
    <Typography as="span" color="white" size="b_xxs" fontWeight="bold" style={{ textTransform: 'uppercase' }}>
      <I18n k="logged_out_homepage.go_hero.new" />
    </Typography>
  </Container>
);
