import React from 'react';
import { bool, string, number, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { getNestedSubCategoryUrl, getSubCategoryUrl } from '../../../utils/url';
import { MP_CAROUSEL_CLICKED } from '../../../utils/biEvents/events/mixPanelEvents';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../utils/biEvents';
import { BQ_CLICKED_POPULAR_SERVICES_CAROUSEL } from '../../../utils/biEvents/events/bigQueryEvents';
import { SUB_CATEGORIES_NAMES } from '../config';
import { GoVoiceOver } from './GoVoiceOver';
import { TouchImage } from './TouchImage';

import styles from './index.module.scss';
import imageStyles from './image.module.scss';
import 'lazysizes';

const source = 'hplo_subcat_first_step';

const SubCategory = ({
    name,
    categorySlug,
    subCategorySlug,
    nestedSubCategorySlug,
    picture,
    image,
    index,
    isInAiTestOut = false,
}) => {
    const { isTouch } = getContext();
    const pos = index + 1;
    const subCategoryNameKey = `logged_out_homepage.subcategory_carousel.${name}.sub`;
    const isVoiceOver = name === SUB_CATEGORIES_NAMES.VOICE_OVERS;
    const shouldShowFiverrGoBadge = isVoiceOver && isInAiTestOut;

    const wrapperClassName = classNames(styles.wrapper, {
        [styles.touch]: isTouch,
        [styles.goVoiceOver]: shouldShowFiverrGoBadge,
    });

    const params = { source, pos };
    const url = nestedSubCategorySlug
        ? getNestedSubCategoryUrl({ categorySlug, subCategorySlug, nestedSubCategorySlug, params })
        : getSubCategoryUrl({ categorySlug, subCategorySlug, params });

    const handleAfterChange = () => {
        sendMixPanelEvent({
            eventName: MP_CAROUSEL_CLICKED,
            params: { order: pos, element: translate(subCategoryNameKey) },
        });

        sendBigQueryEvent({
            eventName: BQ_CLICKED_POPULAR_SERVICES_CAROUSEL,
            params: { position: pos },
        });
    };

    return (
        <div className={wrapperClassName}>
            {shouldShowFiverrGoBadge && <GoVoiceOver subCategoryNameKey={subCategoryNameKey} />}
            <a href={url} className={styles.inner} onClick={handleAfterChange}>
                <h3 className={styles.header}>
                    <I18n k={subCategoryNameKey} />
                </h3>

                {isTouch ? (
                    <TouchImage subCategoryNameKey={subCategoryNameKey} image={image} pos={pos} />
                ) : (
                    <picture>
                        {picture.map((img, index) => (
                            <source
                                key={index}
                                media={img.media}
                                srcSet={img.srcSet}
                                width={img.width}
                                height={img.height}
                            />
                        ))}
                        <img
                            className={classNames(imageStyles.image, 'lazyload')}
                            alt={translate(subCategoryNameKey)}
                            data-src={image.src}
                        />
                    </picture>
                )}
            </a>
        </div>
    );
};

SubCategory.propTypes = {
    name: string,
    categorySlug: string,
    subCategorySlug: string,
    nestedSubCategorySlug: string,
    picture: arrayOf(
        shape({
            media: string,
            srcSet: string,
        })
    ),
    image: shape({
        media: string,
        srcSet: string,
    }),
    index: number,
    isInAiTestOut: bool,
};

export default SubCategory;
