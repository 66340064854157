import React from 'react';
import { bool } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionManager } from '@fiverr-private/impressionable';
import { lazyImport } from '../../../apps/logged_out_homepage/utils/newLazyImport';
import Categories from '../../../apps/logged_out_homepage/components/Categories';
import Seo from '../../../apps/logged_out_homepage/shared/Seo';
import EntryWrapper from '../../../apps/logged_out_homepage/components/EntryWrapper';
import SubcategoryCarousel from '../../../apps/logged_out_homepage/components/SubcategoryCarousel';
import { getSubCategories } from '../../../apps/logged_out_homepage/components/SubcategoryCarousel/config';
import VideoHeroBanner from '../../../apps/logged_out_homepage_freelancer_services/components/VideoHeroBanner';
import shouldShowLogoMakerBanner from '../../../apps/logged_out_homepage/utils/shouldShowLogoMakerBanner';
import { shouldLoadPersonalizedSection } from '../../../apps/logged_out_homepage/components/PersonalizedSectionWrapper/shouldLoad';
import { LAZY_COMPONENTS } from './constants';

const MadeOnFiverr = lazyImport(
  LAZY_COMPONENTS.MADE_ON_FIVERR,
  () => import(/* webpackChunkName: 'MadeOnFiverr' */ '../../../apps/logged_out_homepage/components/MadeOnFiverr')
);

const FiverrBusiness = lazyImport(
  LAZY_COMPONENTS.FIVERR_BUSINESS,
  () => import(/* webpackChunkName: 'FiverrBusiness' */ '../../../apps/logged_out_homepage/components/FiverrBusiness')
);

const FiverrGuides = lazyImport(
  LAZY_COMPONENTS.FIVERR_GUIDES,
  () => import(/* webpackChunkName: 'FiverrGuides' */ '../../../apps/logged_out_homepage/components/FiverrGuides')
);

const Signup = lazyImport(
  LAZY_COMPONENTS.SIGNUP,
  () => import(/* webpackChunkName: 'Signup' */ '../../../apps/logged_out_homepage/components/Signup')
);

const LogoMakerBanner = lazyImport(
  LAZY_COMPONENTS.LOGO_MAKER_BANNER,
  () => import(/* webpackChunkName: 'LogoMakerBanner' */ '../../../apps/logged_out_homepage/components/LogoMakerBanner')
);

const PersonalizedSectionWrapper = lazyImport(
  LAZY_COMPONENTS.PERSONALIZED_SECTION_WRAPPER,
  () =>
    import(
      /* webpackChunkName: 'PersonalizedSectionWrapper' */ '../../../apps/logged_out_homepage/components/PersonalizedSectionWrapper'
    )
);

const Freelancers = lazyImport(
  LAZY_COMPONENTS.FREELANCERS,
  () => import(/* webpackChunkName: 'Freelancers' */ '../../../apps/logged_out_homepage/components/Freelancers')
);

const ProServices = lazyImport(
  LAZY_COMPONENTS.PRO_SERVICES,
  () => import(/* webpackChunkName: 'ProServices' */ '../../../apps/logged_out_homepage/components/ProServices')
);

const Video = lazyImport(
  LAZY_COMPONENTS.VIDEO,
  () => import(/* webpackChunkName: 'Video' */ '../../../apps/logged_out_homepage/components/Video')
);
const LoggedOutHomepageClient = ({ userWasLoggedIn, displayBrandCampaign, inAiLohp, inAiEntryPoints }) => {
  useImpressionManager();
  const showLogoMakerBanner = shouldShowLogoMakerBanner();
  const { locale } = getContext();

  const shouldLoad = shouldLoadPersonalizedSection({ userWasLoggedIn });
  const isInAiTestOut = inAiLohp && inAiEntryPoints;

  return (
    <EntryWrapper displayBrandCampaign={displayBrandCampaign}>
      <Seo />
      <VideoHeroBanner />
      <Categories />
      {shouldLoad && <PersonalizedSectionWrapper userWasLoggedIn={userWasLoggedIn} />}
      <SubcategoryCarousel subCategories={getSubCategories({ locale })} isInAiTestOut={isInAiTestOut} />
      <FiverrBusiness />
      <Video />
      <ProServices />
      <Freelancers />
      {showLogoMakerBanner && !isInAiTestOut && <LogoMakerBanner />}
      <MadeOnFiverr />
      <FiverrGuides />
      <Signup />
    </EntryWrapper>
  );
};

LoggedOutHomepageClient.propTypes = {
  userWasLoggedIn: bool,
  displayBrandCampaign: bool,
  inAiLohp: bool,
  inAiEntryPoints: bool,
};

export default LoggedOutHomepageClient;
