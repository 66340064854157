import graphicsDesign from '../../assets/categories/graphics-design-thin.svg';
import digitalMarketing from '../../assets/categories/digital-marketing-thin.svg';
import programming from '../../assets/categories/programming-tech-thin.svg';
import videoAnimation from '../../assets/categories/video-animation-thin.svg';
import writingTranslation from '../../assets/categories/writing-translation-thin.svg';
import business from '../../assets/categories/business-thin.svg';
import consulting from '../../assets/categories/consulting-thin.svg';
import musicAudio from '../../assets/categories/music-audio-thin.svg';
import aiServices from '../../assets/categories/ai-services-thin.svg';

export const categoriesData = [
    {
        categoryName: 'programming_tech',
        categoryId: 10,
        categorySlug: 'programming-tech',
        categoryImage: programming,
    },
    {
        categoryName: 'graphics_design',
        categoryId: 3,
        categorySlug: 'graphics-design',
        categoryImage: graphicsDesign,
    },
    {
        categoryName: 'digital_marketing',
        categoryId: 2,
        categorySlug: 'online-marketing',
        categoryImage: digitalMarketing,
    },
    {
        categoryName: 'writing_translation',
        categoryId: 5,
        categorySlug: 'writing-translation',
        categoryImage: writingTranslation,
    },
    {
        categoryName: 'video_animation',
        categoryId: 20,
        categorySlug: 'video-animation',
        categoryImage: videoAnimation,
    },
    {
        categoryName: 'ai_services',
        categoryImage: aiServices,
        directLink: '/categories/ai-services',
    },
    {
        categoryName: 'music_audio',
        categoryId: 12,
        categorySlug: 'music-audio',
        categoryImage: musicAudio,
    },
    {
        categoryName: 'business',
        categoryId: 8,
        categorySlug: 'business',
        categoryImage: business,
    },
    {
        categoryName: 'consulting',
        categoryImage: consulting,
        directLink: '/categories/consulting-services',
    },
];

export const categoriesForTofProRedirectExperiment = [10, 2, 8];
