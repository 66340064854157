import React from 'react';
import { SafeComponent } from '@fiverr-private/orca';
import { Image } from '@fiverr-private/media';

interface TrustedByItemProps {
  src: string;
  alt: string;
}

const TrustedByItem = ({ src, alt }: TrustedByItemProps) => (
  <Image width="auto" height="auto" maxHeight="100%" src={src} alt={alt} />
);

export default SafeComponent(TrustedByItem);
