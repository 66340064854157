const { VARIANTS } = require('../types/variants');

const inExperiment = (experimentId, abTests = {}) => {
    const variation = getTestVariation(experimentId, abTests);

    return variation >= VARIANTS.B;
};

const getTestVariation = (experimentId, abTests = {}) => {
    const variation = abTests[experimentId];

    return transformVariation(variation);
};

const isAllocated = (experimentId, abTests = {}) => {
    const variation = Number(abTests[experimentId]);

    return variation >= VARIANTS.A;
};

const transformVariation = (variation) => {
    switch (Number(variation)) {
        case 1:
            return VARIANTS.A;
        case 2:
            return VARIANTS.B;
        case 3:
            return VARIANTS.C;
        default:
            return VARIANTS.A;
    }
};

module.exports = {
    inExperiment,
    getTestVariation,
    isAllocated,
};
